import "./globals.css";
import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import React from "react";
import Neutralino, { init } from "@neutralinojs/lib";
import { isDesktop } from "./hooks/useIsDesktop";

createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <App partyKitHost={PARTYKIT_HOST} />
  </React.StrictMode>
);

if (isDesktop()) {
  console.log("Initializing Neutralino...", Neutralino);
  init();
}

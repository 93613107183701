import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Mic, MicOff, Loader2, Menu, Sparkles, X } from "lucide-react";

import { CallServiceIcon } from "./CallServiceIcon";
import type {
  TranscriptUpdate,
  RecallBot,
  AIQuestionAnswer,
  PartialAIResponse,
  AILatencyMetrics,
  PlaybookProgress,
  PlaybookScriptItem,
  CallQuestion,
} from "party/types";
import { CallStatus } from "./CallStatus";
import { useCallback, useState } from "react";
import { CallModeMenu } from "./CallModeMenu";
import { ErrorNotice } from "./ErrorNotice";
import { TranscriptTable } from "./TranscriptTable";
import { AiHelpBrowser } from "./AiHelpBrowser";
import { LoadingSpinner } from "./LoadingSpinner";
import { PlaybookScriptDisplay } from "./PlaybookScriptDisplay";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { PartyKitServerConnectionStatus } from "./useCallDisplay";
import { QuestionDisplay } from "./QuestionDisplay";
import { HandleGetHelpFunction } from "./AiHelpCard";

export interface CallDisplayUIProps {
  transcript: TranscriptUpdate[];
  roomBot: RecallBot | null;
  aiHistory: AIQuestionAnswer[];
  host: string;
  roomId: string;
  error: string | null;
  clearError?: () => void;
  streamingResponse: PartialAIResponse | null;
  isStreaming: boolean;
  latencyMetrics?: AILatencyMetrics;
  messageLatency: number;
  playbook: PlaybookProgress;
  processScript: boolean;
  currentScriptItem?: PlaybookScriptItem;
  handleGetHelp: HandleGetHelpFunction;
  handleSimulateQuestion: () => void;
  handleSimulateConversation: () => void;
  handleSimulateFullScript: () => void;
  handleSimulateCurrentScriptItem: () => void;
  handleRequestBotInfo: () => void;
  handleReset: () => void;
  handleToggleProcessScript: () => void;
  leaveCall: () => void;
  handleCompleteScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleSetCurrentScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
  questions: CallQuestion[];
}

const TopBar = ({
  roomBot,
  isStreaming,
  handleGetHelp,
  leaveCall,
  setActiveMode,
  processScript,
  handleToggleProcessScript,
}: Readonly<{
  roomBot: RecallBot | undefined;
  isStreaming: boolean;
  processScript: boolean;
  handleGetHelp: HandleGetHelpFunction;
  handleToggleProcessScript: () => void;
  leaveCall: () => void;
  setActiveMode: (mode: "ai" | "transcript" | "script") => void;
}>) => (
  <div>
    <div className="grid grid-cols-3 items-center p-2 border-b border-gray-300 mb-4">
      <div className="justify-self-start">
        <Button
          variant="default"
          size="sm"
          onClick={() => {
            handleGetHelp({});
            setActiveMode("ai");
          }}
          disabled={isStreaming}
          className="w-full"
        >
          <Sparkles size={16} className="mr-2" />
          {isStreaming ? "Getting AI Help..." : "Get AI Help"}
        </Button>
      </div>
      <div className="justify-self-center">
        {roomBot?.meeting_url ? (
          <CallServiceIcon
            url={
              typeof roomBot.meeting_url === "string"
                ? roomBot.meeting_url
                : `https://${roomBot.meeting_url.platform}.com/${roomBot.meeting_url.meeting_id}`
            }
          />
        ) : (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        )}
      </div>
      <div className="justify-self-end flex space-x-2">
        <Button
          size="sm"
          variant="secondary"
          onClick={handleToggleProcessScript}
          className={`w-full ${processScript ? "" : "bg-red-400 hover:bg-red-200"}`}
        >
          {processScript ? <Mic size={16} /> : <MicOff size={16} />}
        </Button>
        <Button
          size="sm"
          variant="secondary"
          onClick={leaveCall}
          className="w-full"
        >
          <X size={16} />
        </Button>
      </div>
    </div>
  </div>
);

const MainContent = ({
  host,
  roomId,
  roomBot,
  error,
  clearError,
  streamingResponse,
  latencyMetrics,
  aiHistory,
  transcript,
  handleGetHelp,
  activeMode,
  playbook,
  currentScriptItem,
  handleCompleteScriptItem,
  handleSetCurrentScriptItem,
  handleRequestBotInfo,
  partyKitServerConnectionStatus,
}: Readonly<{
  host: string;
  roomId: string;
  roomBot: RecallBot | undefined;
  error: string | null;
  clearError?: () => void;
  streamingResponse: PartialAIResponse | null;
  latencyMetrics?: AILatencyMetrics;
  aiHistory: AIQuestionAnswer[];
  transcript: TranscriptUpdate[];
  handleGetHelp: HandleGetHelpFunction;
  activeMode: "transcript" | "script" | "ai";
  playbook: PlaybookProgress;
  currentScriptItem?: PlaybookScriptItem;
  handleCompleteScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleSetCurrentScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleRequestBotInfo: () => void;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
}>) => {
  const aiHelp =
    streamingResponse ||
    (aiHistory.length > 0 ? aiHistory[aiHistory.length - 1] : null);
  const hasJoinedCall = roomBot?.status_changes?.some(
    (status) => status.code === "in_call_recording"
  );
  return (
    <div className="flex-grow overflow-y-auto p-2">
      <div className="h-full flex flex-col">
        {error && (
          <div className="mb-2">
            <ErrorNotice error={error} clearError={clearError} />
          </div>
        )}

        <div
          className={`${activeMode === "script" ? "block" : "hidden"} flex-grow flex-1 min-h-0 flex flex-col space-y-4`}
        >
          <div className="flex-1 min-h-0">
            <PlaybookScriptDisplay
              handleSetCurrentScriptItem={handleSetCurrentScriptItem}
              currentScriptItem={currentScriptItem}
              playbook={playbook}
              handleCompleteScriptItem={handleCompleteScriptItem}
            />
          </div>
          <div className="flex-grow flex-1 min-h-0">
            {hasJoinedCall ? (
              <QuestionDisplay
                roomBot={roomBot}
                onGetAIHelp={handleGetHelp}
                host={host}
                roomId={roomId}
                aiHistory={aiHistory}
              />
            ) : (
              <WaitingForBot
                roomBot={roomBot}
                handleRequestBotInfo={handleRequestBotInfo}
                partyKitServerConnectionStatus={partyKitServerConnectionStatus}
              />
            )}
          </div>
        </div>
        <div className={`${activeMode === "ai" ? "block" : "hidden"}`}>
          <AiHelpBrowser
            help={aiHelp}
            history={aiHistory}
            latencyMetrics={latencyMetrics}
            handleGetHelp={handleGetHelp}
          />
        </div>

        <div
          className={`${activeMode === "transcript" ? "block" : "hidden"} flex flex-col h-full`}
        >
          <div className="flex-grow overflow-hidden">
            <Card className="h-full w-full flex flex-col">
              <TranscriptTable
                transcript={transcript}
                handleGetHelp={handleGetHelp}
              />
            </Card>
          </div>
          <div className="flex-shrink-0 mt-4">
            <LatencyDisplay transcript={transcript} />
          </div>
        </div>
      </div>
    </div>
  );
};

const WaitingForBot = ({
  roomBot,
  handleRequestBotInfo,
  partyKitServerConnectionStatus,
}: {
  roomBot: RecallBot | undefined;
  handleRequestBotInfo: () => void;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
}) => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-col w-full space-y-2">
        <div className="flex-grow-0">
          <h2 className="mb-2">&nbsp;</h2>
        </div>
        <Card className="flex-grow overflow-hidden flex flex-col space-y-8">
          <div>
            <div className="py-8">
              <LoadingSpinner reason={"waiting for bot"} delay={0} />
            </div>
            <div className="text-center py-8">Waiting to join…</div>
            {/* <div>
              <CallStatus
                status={
                  roomBot?.status_changes?.[roomBot?.status_changes?.length - 1]
                    .code ?? "starting_up"
                }
                handleRequestBotInfo={handleRequestBotInfo}
                partyKitServerConnectionStatus={partyKitServerConnectionStatus}
              />
            </div> */}
          </div>
        </Card>
      </div>
    </div>
  );
};

const LatencyDisplay = ({ transcript }: { transcript: TranscriptUpdate[] }) => {
  const latestStartLatency =
    transcript.length > 0
      ? transcript[transcript.length - 1].latency?.toFixed(2)
      : null;
  const latestEndLatency =
    transcript.length > 0
      ? transcript[transcript.length - 1].end_latency?.toFixed(2)
      : null;

  const averageStartLatency =
    transcript.reduce((sum, item) => sum + (item.latency || 0), 0) /
    transcript.length;
  const averageEndLatency =
    transcript.reduce((sum, item) => sum + (item.end_latency || 0), 0) /
    transcript.length;

  const formattedAverageStartLatency = averageStartLatency
    ? averageStartLatency.toFixed(2)
    : "N/A";
  const formattedAverageEndLatency = averageEndLatency
    ? averageEndLatency.toFixed(2)
    : "N/A";

  return (
    <div className="flex flex-col space-y-2 text-xs text-gray-500">
      <div className="flex flex-row space-x-4">
        <div>Start Latency:</div>
        <div>{latestStartLatency}ms</div>
        <div>{formattedAverageStartLatency}ms (Average)</div>
      </div>
      <div className="flex flex-row space-x-4">
        <div>End Latency:</div>
        <div>{latestEndLatency}ms</div>
        <div>{formattedAverageEndLatency}ms (Average)</div>
      </div>
    </div>
  );
};

const Footer = ({
  handleSimulateQuestion = () => {},
  handleSimulateConversation = () => {},
  handleSimulateFullScript = () => {},
  handleSimulateCurrentScriptItem = () => {},
  handleRequestBotInfo = () => {},
  handleReset = () => {},
  roomBot,
  messageLatency,
  partyKitServerConnectionStatus,
  activeMode,
  setActiveMode,
}: Readonly<{
  handleSimulateQuestion: () => void;
  handleSimulateConversation: () => void;
  handleSimulateFullScript: () => void;
  handleSimulateCurrentScriptItem: () => void;
  handleRequestBotInfo: () => void;
  handleReset: () => void;
  roomBot: RecallBot | undefined;
  messageLatency: number;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
  activeMode: "ai" | "transcript" | "script";
  setActiveMode: (mode: "ai" | "transcript" | "script") => void;
}>) => {
  const status = roomBot?.status_changes?.[
    roomBot.status_changes.length - 1
  ] ?? { code: "starting_up" };

  return (
    <div className="flex justify-between items-center p-2 border-t border-gray-300">
      <div className="flex flex-row space-x-4">
        <CallStatus
          status={status?.code}
          handleRequestBotInfo={handleRequestBotInfo}
          partyKitServerConnectionStatus={partyKitServerConnectionStatus}
        />
        <span className="text-xs text-gray-300 p-2.5">{messageLatency} ms</span>
      </div>
      <div className="flex justify-center">
        <CallModeMenu activeMode={activeMode} setActiveMode={setActiveMode} />
      </div>
      <div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="sm" variant="ghost">
              <Menu size={16} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleSimulateQuestion}>
              Simulate question
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleSimulateConversation}>
              Simulate conversation
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleSimulateCurrentScriptItem}>
              Simulate current script item
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleSimulateFullScript}>
              Simulate full script
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleReset}>Reset</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

// Old Debug
{
  /* <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm">
              Debug
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <div className="p-4">
              <Card className="w-80 p-4 max-h-[80vh] overflow-y-auto">
                <ScrollArea className="w-full">
                  <pre className="text-sm word-wrap overflow-x-scroll w-full">
                    {JSON.stringify(roomBot, null, 2)}
                  </pre>
                </ScrollArea>
              </Card>
            </div>
          </PopoverContent>
        </Popover> */
}

export function CallDisplayUI({
  host,
  roomId,
  transcript,
  roomBot,
  aiHistory,
  error,
  clearError,
  streamingResponse,
  isStreaming,
  latencyMetrics,
  messageLatency,
  handleGetHelp,
  handleSimulateQuestion = () => {},
  handleSimulateConversation = () => {},
  handleSimulateFullScript = () => {},
  handleSimulateCurrentScriptItem = () => {},
  handleRequestBotInfo = () => {},
  handleReset = () => {},
  handleCompleteScriptItem = () => {},
  handleSetCurrentScriptItem = () => {},
  handleToggleProcessScript = () => {},
  leaveCall,
  playbook,
  processScript,
  currentScriptItem,
  partyKitServerConnectionStatus,
  questions,
}: Readonly<CallDisplayUIProps>) {
  const [activeMode, setActiveMode] = useState<"ai" | "transcript" | "script">(
    "script"
  );

  const handleGetAIHelp = useCallback(
    ({
      question,
      speakerId,
      itemIds,
    }: {
      question?: string;
      speakerId?: number;
      itemIds?: string[];
    }) => {
      console.log("Getting AI help");
      setActiveMode("ai");
      handleGetHelp({ question, speakerId, itemIds });
    },
    [setActiveMode, handleGetHelp]
  );

  return (
    <div className="flex flex-col h-full">
      <TopBar
        roomBot={roomBot ?? undefined}
        isStreaming={isStreaming}
        handleGetHelp={handleGetAIHelp}
        leaveCall={leaveCall}
        setActiveMode={setActiveMode}
        processScript={processScript}
        handleToggleProcessScript={handleToggleProcessScript}
      />
      <MainContent
        host={host}
        roomId={roomId}
        roomBot={roomBot ?? undefined}
        error={error}
        clearError={clearError}
        streamingResponse={streamingResponse}
        latencyMetrics={latencyMetrics}
        aiHistory={aiHistory}
        transcript={transcript}
        handleGetHelp={handleGetAIHelp}
        activeMode={activeMode}
        playbook={playbook}
        currentScriptItem={currentScriptItem}
        handleCompleteScriptItem={handleCompleteScriptItem}
        handleSetCurrentScriptItem={handleSetCurrentScriptItem}
        handleRequestBotInfo={handleRequestBotInfo}
        partyKitServerConnectionStatus={partyKitServerConnectionStatus}
      />
      <Footer
        messageLatency={messageLatency}
        handleSimulateQuestion={handleSimulateQuestion}
        handleSimulateConversation={handleSimulateConversation}
        handleSimulateFullScript={handleSimulateFullScript}
        handleSimulateCurrentScriptItem={handleSimulateCurrentScriptItem}
        handleRequestBotInfo={handleRequestBotInfo}
        handleReset={handleReset}
        roomBot={roomBot ?? undefined}
        partyKitServerConnectionStatus={partyKitServerConnectionStatus}
        activeMode={activeMode}
        setActiveMode={setActiveMode}
      />
    </div>
  );
}

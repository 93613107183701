import {
  ClerkProvider,
  SignIn,
  SignedIn,
  SignedOut,
  useOrganization,
} from "@clerk/clerk-react";
import { AnalyticsProvider } from "../AnalyticsProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/browser";
import { useIsDesktop } from "../hooks/useIsDesktop";
import NeutralinoShortcuts from "../desktop/NeutralinoShortcuts";

import { useEffect, useState } from "react";
import usePartySocket from "partysocket/react";

import { OrganizationCheck } from "./OrganizationCheck";
import { LogOutTracker } from "./LogOutTracker";
import { UserIdentifier } from "./UserIdentifier";
import { CallManager } from "./CallManager";
import { LoadingSpinner } from "./LoadingSpinner";
import { Toaster } from "@/components/ui/toaster";
import NeutralinoUpdater from "../desktop/NeutralinoUpdater";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://0ffd64644ba8d5268ab52297125c8607@o4507653000855552.ingest.de.sentry.io/4507653006032976",
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

function ErrorFallback({ error }: { error: unknown }) {
  const errorMessage =
    error instanceof Error ? error.message : "An unknown error occurred";

  return (
    <div>
      <Card className="p-8">
        <h1 className="mb-4 text-2xl">Sorry, something went wrong</h1>
        <p className="mb-8">Error: {errorMessage}</p>
        <Button onClick={() => window.location.reload()}>Reload</Button>
      </Card>
    </div>
  );
}

export function App({ partyKitHost }: { partyKitHost: string }) {
  const [clerkPublishableKey, setClerkPublishableKey] = useState<string | null>(
    null
  );
  const [posthogKey, setPosthogKey] = useState<string | null>(null);
  const [posthogEnabled, setPosthogEnabled] = useState<string | null>(null);
  const [authServiceUrl, setAuthServiceUrl] = useState<string | null>(null);

  const partySocket = usePartySocket({
    host: partyKitHost,
    room: "waiting-room",
    onMessage(event) {
      const data = JSON.parse(event.data);
      if (data.type === "CLERK_PUBLISHABLE_KEY") {
        setClerkPublishableKey(data.payload);
      } else if (data.type === "POSTHOG_KEY") {
        setPosthogKey(data.payload);
      } else if (data.type === "POSTHOG_ENABLED") {
        setPosthogEnabled(data.payload);
      } else if (data.type === "AUTH_SERVICE_URL") {
        setAuthServiceUrl(data.payload);
      }
    },
  });

  const isDesktop = useIsDesktop();

  if (!clerkPublishableKey) {
    return (
      <div className="flex items-center justify-center min-h-screen w-full">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <ClerkProvider publishableKey={clerkPublishableKey}>
          <AnalyticsProvider
            posthogKey={posthogKey}
            posthogEnabled={posthogEnabled}
          >
            <main className="min-h-screen">
              <SignedOut>
                <LogOutTracker />
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn />
                </div>
              </SignedOut>
              <SignedIn>
                <UserIdentifier />

                <OrganizationCheck>
                  <CallManager
                    host={partyKitHost}
                    partySocket={partySocket}
                    authServiceUrl={authServiceUrl}
                  />
                </OrganizationCheck>
                {isDesktop && <NeutralinoUpdater />}
              </SignedIn>
            </main>
            <Toaster />
          </AnalyticsProvider>
        </ClerkProvider>
      </QueryClientProvider>
      {isDesktop && <NeutralinoShortcuts />}
    </Sentry.ErrorBoundary>
  );
}
